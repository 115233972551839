import React, { useState } from 'react';
import Button from './Button'; 
import PrivacyPolicyPopup from './PrivacyPolicy';

interface TermsAndConditionsPopupProps {
    isOpen: boolean;
    closePopup: () => void;
  }
  
const TermsAndConditionsPopup: React.FC<TermsAndConditionsPopupProps> = ({ isOpen, closePopup }) => {
    const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false); // State to manage Privacy Policy popup

    if (!isOpen) return null; // Don't render if not open

  return (
    <>
      {/* Dim the background and show the popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-[#FFF8F0] w-4/5 md:w-3/5 lg:w-2/5 p-6 rounded-lg shadow-lg relative overflow-y-auto max-h-[80vh]">
            <div className="p-4 bg-white rounded text-justify text-sm border">
              <h1 className="font-bold text-[#00008b] text-xl text-center mb-12">Termini e Condizioni di Utilizzo</h1>

              <p className="mt-4 leading-relaxed">
                Le presenti Condizioni Generali di Servizio (&quot;Condizioni&quot;) regolano l&apos;accesso e l&apos;utilizzo del servizio offerto da Italian Coffee & Crafts LLC, una piattaforma che fornisce calcoli fiscali automatizzati per investimenti. Utilizzando il nostro sito web e i servizi associati, l&apos;utente accetta di essere vincolato dalle seguenti condizioni.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">1.</span> Accettazione dei Termini
              </h2>
              <p className="mt-2 leading-relaxed">
                L&apos;utilizzo del servizio comporta l&apos;accettazione piena e incondizionata delle presenti Condizioni. Se non si accettano tutte le condizioni indicate, si prega di non utilizzare il servizio.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">2.</span> Descrizione del Servizio
              </h2>
              <p className="mt-2 leading-relaxed">
                Italian Coffee & Crafts LLC fornisce un servizio di calcolo fiscale per investimenti, basato sui dati forniti dall&apos;utente. Gli utenti accedono alla piattaforma, inseriscono i loro dati seguendo le istruzioni e ricevono un report fiscale in formato elettronico.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">3.</span> Utilizzo del Servizio
              </h2>
              <p className="mt-2 leading-relaxed">
                L&apos;utente è tenuto a:
              </p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li>Fornire dati accurati e completi durante l&apos;utilizzo del servizio;</li>
                <li>Utilizzare il servizio esclusivamente per scopi personali e non commerciali;</li>
                <li>Non condividere le proprie credenziali di accesso con terzi.</li>
              </ul>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">4.</span> Generazione e Conservazione del Report Fiscale
              </h2>
              <p className="mt-2 leading-relaxed">
                Il report fiscale generato dal nostro servizio è basato esclusivamente sui dati forniti dall&apos;utente. È responsabilità esclusiva dell&apos;utente conservare il report generato e i relativi dati. Italian Coffee & Crafts LLC non si assume alcuna responsabilità per la conservazione dei report, e l&apos;utente è tenuto a conservare in modo sicuro una copia del report generato.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">5.</span> Limitazione di Responsabilità
              </h2>
              <p className="mt-2 leading-relaxed">
                Italian Coffee & Crafts LLC offre un servizio automatizzato di calcolo fiscale, ma non garantisce l&apos;esattezza o la completezza del report generato. L&apos;utente accetta che l&apos;utilizzo del servizio avviene a proprio esclusivo rischio e pericolo.
              </p>
              <p className="mt-2 leading-relaxed">
                In nessun caso Italian Coffee & Crafts LLC sarà responsabile per eventuali danni diretti, indiretti, speciali, punitivi o consequenziali derivanti da:
              </p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li>Errori o inesattezze nei calcoli fiscali generati;</li>
                <li>Mancata conservazione o perdita del report fiscale da parte dell&apos;utente;</li>
                <li>Qualsiasi uso improprio del servizio da parte dell&apos;utente.</li>
              </ul>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">6.</span> Dichiarazione di Esonero da Responsabilità
              </h2>
              <p className="mt-2 leading-relaxed">
                L&apos;utente riconosce che il servizio è fornito &quot;così com&apos;è&quot; e &quot;come disponibile&quot;, senza garanzie di alcun tipo, esplicite o implicite. Italian Coffee & Crafts LLC non garantisce che i risultati del calcolo siano privi di errori o rispecchino la legislazione fiscale vigente.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">7.</span> Politica di Rimborso
              </h2>
              <p className="mt-2 leading-relaxed">
                Il servizio può essere rimborsato nelle seguenti circostanze:
              </p>
              <ul className="list-disc list-inside ml-4 mt-2 leading-relaxed">
                <li>Se l&apos;utente ha effettuato il pagamento ma non ha ancora finalizzato il report.</li>
                <li>Se l&apos;utente dimostra che il report generato contiene errori di responsabilità del software.</li>
              </ul>
              <p className="mt-2 leading-relaxed">
                Qualsiasi altra richiesta di rimborso sarà valutata a discrezione di Italian Coffee & Crafts LLC.
              </p>
              <p className="mt-2 leading-relaxed">
                I rimborsi saranno effettuati utilizzando lo stesso metodo di pagamento originario.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">8.</span> Manleva
              </h2>
              <p className="mt-2 leading-relaxed">
                L&apos;utente si impegna a manlevare e tenere indenne Italian Coffee & Crafts LLC da qualsiasi pretesa derivante dall&apos;uso del servizio o dalla violazione delle presenti Condizioni.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">9.</span> Modifiche ai Termini
              </h2>
              <p className="mt-2 leading-relaxed">
                Italian Coffee & Crafts LLC si riserva il diritto di modificare in qualsiasi momento le presenti Condizioni. L&apos;utente sarà informato di eventuali modifiche tramite il sito web o i canali di comunicazione utilizzati dalla piattaforma.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">10.</span> Interruzione del Servizio
              </h2>
              <p className="mt-2 leading-relaxed">
                Italian Coffee & Crafts LLC si riserva il diritto di interrompere temporaneamente o definitivamente il servizio senza preavviso in caso di necessità tecnica, operativa o legale. Nessuna responsabilità sarà assunta per eventuali interruzioni.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">11.</span> Diritto di Recesso
              </h2>
              <p className="mt-2 leading-relaxed">
                L&apos;utente riconosce che, trattandosi di un servizio digitale erogato immediatamente dopo il pagamento, il diritto di recesso non può essere esercitato una volta completata la fornitura del servizio.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">12.</span> Privacy e Protezione dei Dati
              </h2>
              <p className="mt-2 leading-relaxed">
                I dati personali forniti dall&apos;utente saranno trattati in conformità alla nostra <strong
                  className="underline cursor-pointer color-primary"
                  onClick={() => setPrivacyPolicyOpen(true)}
                > Informativa sulla Privacy
                </strong>. L&apos;utente acconsente al trattamento dei propri dati personali in conformità a tale informativa.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">13.</span> Divulgazione a Terze Parti
              </h2>
              <p className="mt-2 leading-relaxed">
                I dati personali e i dati di transazione degli utenti non saranno condivisi con terze parti, a meno che non sia richiesto per legge o in caso di espressa autorizzazione dell&apos;utente.
              </p>
              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">14.</span> Analisi degli Investimenti e Certificati
              </h2>
              <p className="mt-2 leading-relaxed">
                Il nostro servizio offre uno strumento di analisi degli investimenti per verificare le performance degli utenti. Gli utenti possono inserire i propri dati finanziari e ottenere un report dettagliato, che include una valutazione delle loro strategie di investimento.
              </p>
              <p className="mt-2 leading-relaxed">
                Gli utenti che soddisfano determinati criteri di successo possono inoltre richiedere un certificato di accreditamento, che attesti il loro rendimento come investitori di successo. Questo certificato è soggetto a una tariffa separata, distinta da quella relativa alla reportistica fiscale.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">15.</span> Responsabilità degli Utenti e Validità dei Certificati
              </h2>
              <p className="mt-2 leading-relaxed">
                È responsabilità esclusiva degli utenti garantire la correttezza e la veridicità dei dati inseriti nel nostro sistema di analisi degli investimenti. Geraldone non verifica la veridicità dei dati caricati e non è responsabile per eventuali errori, inesattezze o manipolazioni dei dati da parte degli utenti.
              </p>
              <p className="mt-2 leading-relaxed">
                In caso di manipolazione dei dati inseriti, al fine di ottenere una valutazione più favorevole o di qualificarsi per un certificato, il certificato e qualsiasi accreditamento emesso da Geraldone sarà considerato nullo e non valido. L&apos;utente è l&apos;unico responsabile per la manipolazione dei dati e per qualsiasi conseguenza che ne derivi.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">16.</span> Costi del Certificato e Limitazioni di Responsabilità
              </h2>
              <p className="mt-2 leading-relaxed">
                L&apos;acquisizione del certificato di accreditamento comporta un costo separato, il cui importo verrà indicato al momento della richiesta. Una volta emesso, il certificato non è rimborsabile, indipendentemente dal suo utilizzo da parte dell&apos;utente.
              </p>
              <p className="mt-2 leading-relaxed">
                Geraldone non si assume alcuna responsabilità per l&apos;uso o la divulgazione del certificato da parte dell&apos;utente. In nessun caso Geraldone sarà responsabile per eventuali danni, diretti o indiretti, derivanti dall&apos;utilizzo del certificato o dalla sua distribuzione a terzi.
              </p>
              <p className="mt-2 leading-relaxed">
                Gli utenti comprendono e accettano che la validità del certificato dipende esclusivamente dall&apos;integrità dei dati inseriti e che Geraldone non sarà responsabile per certificati emessi sulla base di dati inaccurati o manipolati.
              </p>
              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                  <span className="text-[#ffa500]">17.</span> Proprietà Intellettuale e Divieto di Riproduzione
              </h2>
              <p className="mt-2 leading-relaxed">
                  Tutti i diritti relativi al software, al sito web e ai servizi offerti da Geraldone, inclusi ma non limitati a codice sorgente, design, testi, marchi, loghi, e qualsiasi altro contenuto correlato, sono di esclusiva proprietà di Geraldone o dei suoi licenziatari e sono protetti dalle leggi sulla proprietà intellettuale applicabili.
              </p>
              <p className="mt-2 leading-relaxed">
                  L&apos;utente riconosce e accetta che non è autorizzato a copiare, riprodurre, distribuire, modificare, decompilare, disassemblare o tentare in qualsiasi modo di ottenere il codice sorgente del software utilizzato per fornire i servizi di Geraldone. Qualsiasi tentativo in tal senso costituisce una violazione delle presenti Condizioni e della legge applicabile.
              </p>
              <p className="mt-2 leading-relaxed">
                  L&apos;uso del software è limitato all&apos;accesso e all&apos;utilizzo personale da parte dell&apos;utente, per i soli fini previsti dal servizio. L&apos;utente non può creare opere derivate basate sul software né utilizzarlo per sviluppare prodotti concorrenti.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                  <span className="text-[#ffa500]">18.</span> Misure di Sicurezza e Protezione del Software
              </h2>
              <p className="mt-2 leading-relaxed">
                  Geraldone si riserva il diritto di adottare misure tecniche e legali per proteggere il proprio software e servizi da utilizzi non autorizzati, inclusa l&apos;implementazione di tecnologie di controllo dell&apos;accesso e sistemi di monitoraggio per rilevare eventuali violazioni.
              </p>
              <p className="mt-2 leading-relaxed">
                  Qualsiasi utilizzo non autorizzato, tentativo di copia o riproduzione del software sarà considerato una violazione grave delle presenti Condizioni e potrà comportare azioni legali, incluse richieste di risarcimento danni e l&apos;adozione di misure cautelari a tutela dei diritti di Geraldone.
              </p>

              <h2 className="font-bold text-[#00008b] text-lg mt-6">
                <span className="text-[#ffa500]">19.</span> Risoluzione delle controversie
              </h2>
              <p className="mt-2 leading-relaxed">
                In caso di controversia relativa a un pagamento o al servizio, l&apos;utente è invitato a contattare Italian Coffee & Crafts LLC. Le controversie saranno risolte in buona fede.
              </p>
              <p className="mt-2 leading-relaxed">
                Nel caso in cui un cliente si ritenga insoddisfatto dalla risoluzione, le presenti Condizioni sono regolate dalla legge del Kosovo. Qualsiasi controversia irrisolta sarà di competenza esclusiva del Foro di Prishtina, Kosovo.
              </p>

              <p className="mt-4">Ultimo aggiornamento: 09/11/2024</p>
            </div>

            {/* Close button at the bottom center */}
            <div className="flex justify-center mt-6">
              <Button variant="blue" onClick={closePopup} className="text-xl py-2 px-5">
                Chiudi
              </Button>
            </div>
          </div>
        </div>
      )}
      <PrivacyPolicyPopup isOpen={isPrivacyPolicyOpen} closePopup={() => setPrivacyPolicyOpen(false)} />
    </>
  );
};

export default TermsAndConditionsPopup;
